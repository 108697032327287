<template>
  <apollo-query
    v-if="!isScreenLoading && isScreen ? commonChatId : channelId"
    ref="queryMessages"
    :query="queries.messages"
    fetchPolicy="no-cache"
    :debounce="1"
    :variables="{
      channel_id: isScreen ? commonChatId : channelId,
      first: offset,
      after: limit,
      descending: false,
    }"
    class="messages"
    @result="loadMessages"
  >
    <template v-slot="{ result: { error, data }, query, isLoading }">
      <header-chat v-if="!isScreen">
        <div v-if="!isGuest" class="header-chat__button" path-back="messenger" @click="goHome">
          <ws-icon class="icon-arrow-left" />
          <div>{{ $t("Chat.allChats") }}</div>
        </div>

        <div class="info-chat" :class="{ 'info-chat--guest': isGuest }" @click="toggleSettingsChat">
          <ws-avatar
            :class="{ 'info-chat__avatar--guest': isGuest }"
            :src="avatarChannel"
            :title="titleChannel"
          />
          <div class="info-chat__title">{{ titleChannel }}</div>
        </div>
        <div
          v-if="!isPublicChannel"
          ref="dropdownButton"
          class="container-icon header-chat__item"
          @click="toggleDropDownMenuChannel"
        >
          <ws-icon class="icon-options-vertical"></ws-icon>
          <div v-show="openDropDownMenu" ref="dropdown" class="menu-dropdown">
            <div v-if="isGroupChannel" class="menu-dropdown__item" @click="exitFromChat">
              {{ $t("Chat.exitFromChat") }}
            </div>
            <div v-if="isPrivateChannel || isAdmin" class="menu-dropdown__item" @click="removeChat">
              {{ $t("Chat.deleteChatAndExit") }}
            </div>
          </div>
        </div>
      </header-chat>

      <loader v-if="isLoading" class="apollo-query__loading" :animated="false" />
      <div v-else-if="error" class="error apollo">
        {{ $t("common.error") }}
      </div>

      <div v-if="!messages.length && data && !error" class="messages__list--empty">
        <p>{{ $t("Chat.created") }}</p>
        <p>{{ $t("Chat.hello") }}</p>
      </div>
      <message-list
        v-show="messages.length && data"
        ref="messageList"
        :is-screen="isScreen"
        :is-scroll-bottom="scrollBottom"
        :messages="messages"
        class="messages__list"
        @load-more-messages="loadMoreMessages(query)"
      />

      <setting-group-chat
        v-if="!isScreen && showSettingChat"
        :title-channel="titleChannel"
        :avatar-channel="avatarChannel"
        :type-channel="typeChannel"
        :is-admin="isAdmin"
        :users-total-count="usersTotalCount"
        :online-count="onlineCount"
        @click="toggleSettingsChat"
        @avatar-updated="updateChannelInfo"
        @avatar-loading="showAvatarLoader"
        @start-private="startPrivateChat"
        @title-updated="titleUpdated"
      />
      <loader v-if="isShowAvatarLoader" class="avatar__loading" />

      <user-info v-if="showUserInfo" @click="toggleSettingsChat" />

      <user-input v-if="!isScreen" class="messages__input" @sendMessage="sendMessage" />
    </template>
  </apollo-query>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import Loader from "@/components/common/elements/Loader";
import { auth, chats, messenger, room } from "@/store/modules/store.namespaces";
import { CHATS_RESET_STATE, CHATS_SET_PROPS } from "@/store/modules/chats/mutation-types";
import { channelTypes } from "@/constants/chats/chats-consts";
import { convertImageUri } from "@/components/chat/ChatUtils.js";
import * as roomGetterKeys from "@/store/modules/common/getter-types";
import HeaderChat from "../components/Header";
import WsAvatar from "../components/Avatar";
import UserInput from "../components/UserInput";
import MessageList from "../components/MessageList";
import WsIcon from "../components/Icon";
import SettingGroupChat from "../components/SettingGroupChat";
import UserInfo from "../components/UserInfo";
import { GET_MESSAGES_FROM_CHANNEL, GET_PROFILE, GET_CHANNEL_INFO } from "../GraphQl/query/query";
import {
  ADD_MESSAGE_TO_CHANNEL,
  REMOVE_USER_FROM_CHANNEL,
  REMOVE_CHANNEL,
  START_PRIVATE_CHANNEL,
} from "../GraphQl/mutation/mutation";
import {
  CHAT_ADDED_MESSAGE_TO_CHANNEL,
  CHAT_UPDATE_CHANNEL,
  CHAT_CLEAR,
} from "../GraphQl/subscriptions/subscriptions";

export default {
  name: "Messages",
  components: {
    HeaderChat,
    WsAvatar,
    UserInput,
    MessageList,
    WsIcon,
    SettingGroupChat,
    UserInfo,
    Loader,
  },
  props: {
    isScreen: {
      type: Boolean,
      default: false,
    },
    unreadCount: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      isScreenLoading: false,
      titleChannel: "",
      messages: [],
      showSettingChat: false,
      showUserInfo: false,
      admin: false,
      openDropDownMenu: false,
      first: 0,
      after: 0,
      scrollBottom: true,
      typeChannel: "public",
      loadMore: true,
      isShowAvatarLoader: false,
      queries: {
        messages: GET_MESSAGES_FROM_CHANNEL,
        channelInfo: GET_CHANNEL_INFO,
      },
      subscribes: {
        update: CHAT_UPDATE_CHANNEL,
        messages: CHAT_ADDED_MESSAGE_TO_CHANNEL,
      },
      totalCount: 0,
      usersTotalCount: 0,
      isAdmin: false,
      channelName: null,
      onlineCount: 0,
    };
  },
  computed: {
    ...mapState(chats, {
      avatarChannel: state => state.avatar,
    }),
    ...mapState(chats, ["channelId", "userId", "lastMsgId", "countMsgs"]),
    ...mapState(auth, {
      groupChatId: state => +state.user.groupChatId,
      isGuest: state => !!state.user.is_guest,
    }),
    ...mapState(messenger, ["anonymous"]),
    ...mapState(room, {
      commonChatId: state => state.roomInfo.external_chat_id,
      entityId: state => state.roomInfo?.external_chat_entity_id,
    }),
    ...mapGetters(room, {
      availableRoomModules: roomGetterKeys.AVAILABLE_ROOM_MODULES,
    }),
    hasAnonymous() {
      return this.availableRoomModules.allowAnonymity;
    },
    numberOfMessageForLoad() {
      return this.countMsgs < 10 ? 10 : +this.countMsgs;
    },
    limit() {
      const limit = this.lastMsgId + +this.unreadCount - this.numberOfMessageForLoad;
      if (limit === 0) return +this.unreadCount - this.offset;
      if (limit < 0 || Number.isNaN(limit)) return 0;
      return limit;
    },
    isPublicChannel() {
      return this.typeChannel === "public";
    },
    isGroupChannel() {
      return this.typeChannel === "group";
    },
    isPrivateChannel() {
      return this.typeChannel === "private";
    },
    isAnonymous() {
      return !!this.anonymous && this.hasAnonymous;
    },
    offset() {
      return Math.min(11, this.numberOfMessageForLoad + 1);
    },
  },
  watch: {
    commonChatId() {
      this.goHome();
    },
    channelId() {
      this.reloadChannelInfo();
      this.updateChannelInfo();
    },
    "$i18n.locale": function() {
      this.titleChannel = this.getTitle({
        name: this.channelName,
        id: this.channelId,
        type: channelTypes[this.typeChannel],
      });
    },
  },
  beforeDestroy() {
    this.removeListeners();
    const dropdownMenu = document.body.querySelector("#dropdown");
    if (this.$isIOS && dropdownMenu) {
      dropdownMenu.remove();
    }
  },
  async created() {
    if (this.isScreen) {
      await this.initScreen();
    }
    this.first = this.offset;
    this.after = this.limit;
  },
  mounted() {
    this.addListeners();
  },
  methods: {
    ...mapMutations(chats, {
      chatsResetStoreState: CHATS_RESET_STATE,
      updateChatsProps: CHATS_SET_PROPS,
    }),
    async initScreen() {
      try {
        this.isScreenLoading = true;
        const { data } = await this.channelInfoQuery();
        this.updateChatsProps({
          channelId: this.commonChatId,
          lastMsgId: data.chatFindChannel.messages.totalCount - 1,
        });
      } finally {
        this.isScreenLoading = false;
      }
    },
    showAvatarLoader() {
      this.isShowAvatarLoader = true;
    },
    loadMessages(data) {
      const channelInfo = data.data.chatFindChannel;
      const count = channelInfo?.messages?.totalCount;
      this.totalCount = count;
      this.usersTotalCount = channelInfo?.userConnections?.totalCount;
      this.onlineCount = channelInfo?.userConnections?.additionalInfo.onlineCount;
      if (!this.messages.length || !this.first) {
        this.titleChannel = this.getTitle({ ...channelInfo });
        this.typeChannel = channelTypes[channelInfo.type];
        this.channelName = channelInfo.name;
        this.getProfileUser(channelInfo.admin.id);
      }
      if (channelInfo?.messages) {
        /**
         * check duplicates */
        if (!this.messages.length) {
          this.messages = channelInfo.messages.edges;
        }
        const messages = channelInfo.messages.edges.filter(
          message => !this.messages.some(thisMes => thisMes.id === message.id),
        );
        if (messages.length) {
          this.messages = messages.concat(this.messages);
        }
      }
      if (count && !this.messages.length) {
        this.first = 10;
        this.after = 0;
      }
      if (channelInfo?.admin && this.userId === channelInfo.admin.id) {
        this.isAdmin = true;
      }
    },
    addListeners() {
      if (this.$isDesktop) return;
      window.addEventListener("focus", this.reloadMessages);
    },
    removeListeners() {
      if (this.$isDesktop) return;
      window.removeEventListener("focus", this.reloadMessages);
    },
    onMessageAdded(message) {
      this.scrollBottom = true;
      const candidate = {
        node: message,
        id: message.local_id,
      };
      if (!this.messages.some(item => item.id === candidate.id)) {
        this.messages.push(candidate);
      }
    },
    async reloadMessages() {
      await this.$refs.queryMessages.getApolloQuery().refetch();
      this.messages = [];
      await this.$refs.queryMessages.getApolloQuery().refetch({
        first: this.offset,
        after: this.totalCount - this.offset,
      });
    },
    channelInfoQuery(channelId) {
      const channel_id = channelId || this.commonChatId;
      return this.$apollo.query({
        query: this.queries.messages,
        fetchPolicy: "network-only",
        variables: {
          channel_id,
          first: 0,
          after: 0,
        },
      });
    },
    updateChannelInfoQuery() {
      return this.$apollo.query({
        query: this.queries.channelInfo,
        fetchPolicy: "network-only",
        variables: {
          channel_id: this.channelId,
        },
      });
    },
    updateChannelInfo() {
      this.updateChannelInfoQuery()
        .then(data => {
          const { avatar, name, id, type } = data.data.chatFindChannel;
          const title = this.getTitle({ name, id, type });
          this.titleUpdated(title);
          if (avatar) {
            this.updateChatsProps({ avatar: convertImageUri(avatar) });
          }
          this.isShowAvatarLoader = false;
        })
        .catch(error => {
          this.isShowAvatarLoader = false;
          throw new Error(error);
        });
    },
    titleUpdated(title) {
      this.titleChannel = title;
      this.reloadChannelInfo();
    },
    reloadChannelInfo() {
      this.updateChannelInfoQuery()
        .then(data => {
          const { avatar, id } = data.data.chatFindChannel;
          const chatProps = { channelId: id };
          if (avatar) {
            chatProps.avatar = convertImageUri(avatar);
          }
          this.updateChatsProps(chatProps);
          this.showSettingChat = false;
        })
        .catch(error => {
          this.isShowAvatarLoader = false;
          throw new Error(error);
        });
    },
    startPrivateChat(user) {
      this.$apollo
        .mutate({
          mutation: START_PRIVATE_CHANNEL,
          variables: {
            user_id: user.id,
            entity_id: this.entityId,
          },
        })
        .then(data => {
          this.updateChatsProps({
            channelId: data.data.startPrivate.id,
            lastMsgId: 0,
            countMsgs: 10,
            userId: user.id,
            avatar: user.node.avatar,
          });
          this.messages = [];
        });
    },
    getTitle({ name, type, id }) {
      if (channelTypes[type] === "public") {
        let title = "";
        switch (id) {
          case this.commonChatId:
            title = this.$t("Chat.commonTitle");
            break;
          case this.groupChatId:
            title = this.$t("Chat.groupTitle");
            break;
          default:
            break;
        }
        return title;
      }
      return name;
    },
    async loadMoreMessages(query) {
      if (!this.after) return;
      const rest = this.after - this.first;
      this.first = rest > 0 ? 10 : this.after;
      this.after = rest > 0 ? rest : 0;
      this.scrollBottom = false;
      await query.fetchMore({
        variables: {
          channel_id: this.isScreen ? this.commonChatId : this.channelId,
          first: this.first,
          after: this.after,
          descending: false,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return fetchMoreResult;
        },
      });
    },

    toggleDropDownMenuChannel() {
      this.openDropDownMenu = !this.openDropDownMenu;
      if (this.$isIOS) {
        this.$nextTick(() => {
          this.moveDropdownToBody();
        });
      }
    },

    moveDropdownToBody() {
      const dropdownButtonElRect = this.$refs.dropdownButton.getBoundingClientRect();
      const dropdownElRect = this.$refs.dropdown.getBoundingClientRect();
      const dropdownEl = this.$refs.dropdown;
      if (this.openDropDownMenu) {
        dropdownEl.style.top = `${dropdownButtonElRect.top + dropdownButtonElRect.height}px`;
        dropdownEl.style.left = `${dropdownButtonElRect.width / 2 +
          dropdownButtonElRect.left -
          dropdownElRect.width}px`;
        if (!document.body.querySelector("#dropdown")) {
          dropdownEl.id = "dropdown";
          document.body.appendChild(dropdownEl);
        }
      }
    },

    getProfileUser(idAdmin) {
      this.$apollo
        .query({
          query: GET_PROFILE,
        })
        .then(data => {
          if (idAdmin === data.data.profile.user.id) {
            this.admin = true;
          } else {
            this.admin = false;
          }
        });
    },
    toggleSettingsChat() {
      if (!this.isPrivateChannel && !this.isGuest) {
        this.showSettingChat = !this.showSettingChat;
        this.openDropDownMenu = false;
      }
    },
    /**
     * Метод отправки сообщения в чат.
     *
     * @param {string} message - текст сообщения
     */
    sendMessage(message) {
      this.$apollo.mutate({
        mutation: ADD_MESSAGE_TO_CHANNEL,
        variables: {
          channel_id: this.channelId,
          text: message,
          anonymous: this.isAnonymous && !this.isGuest,
        },
      });
    },
    exitFromChat() {
      this.$apollo
        .mutate({
          mutation: REMOVE_USER_FROM_CHANNEL,
          variables: {
            user_ids: Array.from(this.userId),
            channel_id: this.channelId,
          },
        })
        .then(() => this.goBack());
      this.toggleDropDownMenuChannel();
    },
    removeChat() {
      this.$apollo
        .mutate({
          mutation: REMOVE_CHANNEL,
          variables: {
            channel_id: this.channelId,
          },
        })
        .then(() => this.goBack());
      this.toggleDropDownMenuChannel();
    },
    goBack(reset) {
      if (reset) this.chatsResetStoreState();
      this.$emit("go-back-view", -1);
    },
    goHome() {
      this.chatsResetStoreState();
      this.$emit("go-home-view");
    },
  },
  apollo: {
    $subscribe: {
      /**
       * Событие apollo по добавлению нового сообщения в чат.
       * subscription chatAddedMessageToChannel
       */
      chatAddedMessageToChannel: {
        query: CHAT_ADDED_MESSAGE_TO_CHANNEL,
        variables() {
          return {
            channel_id: this.channelId,
          };
        },
        result({ data }) {
          this.onMessageAdded(data.chatAddedMessageToChannel);
        },
      },
      chatUpdateChannel: {
        query: CHAT_UPDATE_CHANNEL,
        variables() {
          return {
            channel_id: this.channelId,
          };
        },
        result({ data }) {
          this.updateChannelInfo(data.chatUpdateChannel);
        },
      },
      /**
       * Событие apollo по очистке чата.
       * subscription chatClearMessages
       */
      chatClearMessages: {
        query: CHAT_CLEAR,
        variables() {
          return {
            channel_id: this.channelId,
          };
        },
        result() {
          this.messages = [];
        },
      },
    },
  },
};
</script>

<style scoped lang="less">
@black: #303030;

.messages {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  &__list--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
  }
}

.header-chat__item {
  display: flex;
  align-items: center;
  min-width: 30px;
  height: 100%;
  /*overflow-x: hidden;*/
  white-space: nowrap;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  z-index: 10;
  width: 182px;
  padding: 15px;
  font-size: 16px;
  color: @black;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-dropdown__item {
  width: 100%;
  cursor: pointer;

  &:active {
    background-color: #9e9d9d73;
  }
}

.menu-dropdown__item:not(:last-child) {
  margin-bottom: 15px;
}

.messages__input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 99px;
}

.avatar__loading {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(39, 33, 18, 0.3);
}

.header-chat__button {
  display: flex;
  align-items: center;
  width: 100px;
  cursor: pointer;
}

.info-chat {
  display: flex;
  align-items: center;
  max-width: 230px;
  overflow-x: hidden;
  cursor: pointer;

  &__title {
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--guest {
    margin-left: 8px;
    cursor: auto;
  }

  &__avatar {
    &--guest {
      cursor: auto;
    }
  }
}
</style>
