<template>
  <div class="anonymous-checkbox text-sm">
    <input
      :id="id"
      class="anonymous-checkbox__input visually-hidden"
      name="anonymous"
      type="checkbox"
      :checked="checked"
      @change="onChangeCheckboxHandler"
    />
    <label class="anonymous-checkbox__label" :for="id">{{ $t("Messenger.anonymous") }}</label>
  </div>
</template>

<script>
/**
 * Чекбокс включения и отключения анонимности в чате и вопроса спикеру
 * @vue-prop {Boolean} checked - включен/выключен
 * @vue-prop {String} id - уникальный ид для инпута
 * @vue-event {Boolean} change - изменяет состояние чекбокса
 */
export default {
  name: "AnonymousCheckbox",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: Boolean,
    id: {
      type: String,
      default: "anonymous",
    },
  },
  methods: {
    onChangeCheckboxHandler(event) {
      this.$emit("change", event.target.checked);
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/components/icons/_variables.less";
@import "~@/styles/components/icons/solid.less";
.anonymous-checkbox {
  display: flex;
  align-items: center;
  &__label {
    position: relative;
    padding-left: 30px;
    color: #313131;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 16px;
      height: 16px;
      border: 1px solid var(--base-color);
      border-radius: 3px;
    }
    &::after {
      display: none;
      .fas;
      content: @fa-var-check;
      position: absolute;
      left: 3px;
      top: 1px;
      font-size: 10px;
      color: var(--base-color);
    }
  }
  &__input:checked + &__label::after {
    display: block;
  }
}
</style>
