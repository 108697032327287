<template>
  <div class="preview">
    <ws-button class="preview__button" color="default" icon @click="$emit('close')">
      <ws-icon light xl>times</ws-icon>
    </ws-button>
    <img :src="src" alt="avatar" class="preview__image" />
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "AvatarPreview",
  components: {
    WsButton,
    WsIcon,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
.preview {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  &__button {
    position: absolute;
    top: 65px;
    right: 5px;
    background-color: #fff;

    &::before {
      background-color: #fff;
    }
  }

  &__image {
    align-self: center;
    object-fit: contain;
    justify-self: center;
    width: 95%;
    margin: auto;
  }
}
</style>
