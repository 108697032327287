var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{ref:"list",staticClass:"apollo-query",attrs:{"query":_vm.queries.users,"fetchPolicy":"network-only","variables":_vm.variables},on:{"result":_vm.getUsers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading && !_vm.usersInChannel.length)?_c('loader',{staticClass:"apollo-query__loading",attrs:{"center":"","animated":false}}):(error)?_c('div',{staticClass:"apollo-query__error"},[_vm._v(" "+_vm._s(_vm.$t("common.error"))+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.usersInChannel.length || _vm.foundUsersByName.length) && !error),expression:"(usersInChannel.length || foundUsersByName.length) && !error"}],staticClass:"apollo-query__result"},_vm._l((_vm.users),function(user){return _c('ws-item-list',{key:user.id,class:{
          'ws-item-list--cursor-default': _vm.privateChatsDisabled,
        },attrs:{"item":user,"title":user.node.name,"image":user.node.avatar,"is-dot":true,"show-dot":user.node.online,"is-admin":user.id === data.chatFindChannel.admin.id}},[_c('template',{slot:"icon"},[(user.id !== _vm.userId)?_c('user-list-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.privateChatsDisabled),expression:"!privateChatsDisabled"}],attrs:{"user":user,"channel-id":_vm.currentChannelId,"icon":_vm.icon,"active-icon":_vm.activeIcon},on:{"click":function($event){return _vm.selectUser({ user: user, admin: data.chatFindChannel.admin })}}}):_vm._e()],1)],2)}),1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }