import gql from "graphql-tag";

export const CHAT_JOINED_USER_TO_CHANNEL = gql`
  subscription chatJoinedUserToChannel($channel_id: Int, $user_id: Int) {
    chatJoinedUserToChannel(channel_id: $channel_id, user_id: $user_id) {
      id
    }
  }
`;

export const CHAT_ADDED_MESSAGE_TO_CHANNEL = gql`
  subscription chatAddedMessageToChannel($channel_id: Int!) {
    chatAddedMessageToChannel(channel_id: $channel_id) {
      id
      local_id
      text
      user {
        name
        id
        is_guest
      }
    }
  }
`;

export const CHAT_UPDATE_CHANNEL = gql`
  subscription chatUpdatedChannel($channel_id: Int!) {
    chatUpdatedChannel(channel_id: $channel_id) {
      name
      avatar
    }
  }
`;
/**
 * Событие graphql по очистке чата.
 */
export const CHAT_CLEAR = gql`
  subscription chatClearMessages($channel_id: Int!) {
    chatClearMessages(channel_id: $channel_id){
      name
    }
  }
`;
