<template>
  <div class="ws-item-list" @click="$emit('click', item)">
    <ws-badge :value="count || ''" :isDot="isDot" :showDot="Boolean(count) || showDot">
      <ws-avatar :src="image" :title="title"></ws-avatar>
    </ws-badge>
    <div class="ws-item-list__title">{{ title }}</div>
    <slot name="icon"></slot>
    <slot></slot>
  </div>
</template>

<script>
import WsBadge from "./Badge";
import WsAvatar from "./Avatar";

export default {
  name: "WsItemList",
  components: {
    WsBadge,
    WsAvatar,
  },
  props: {
    item: {
      type: Object,
    },
    showDot: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
    },
    count: {
      type: [Number, String],
      default: "",
    },
    icon: {
      type: String,
    },
    image: {
      type: String,
      default: "",
    },
    isDot: {
      type: Boolean,
    },
    imageSize: {
      type: Number,
      default: 22,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$emit("mounted", this.item);
  },
};
</script>

<style scoped lang="less">
.ws-item-list {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;

  /**hack for safari */
  transform: translate3d(0, 0, 0);

  &:first-of-type {
    margin-top: 10px;
    border-top: 1px solid #dcdcdc;
  }
}

.ws-item-list__title {
  flex: 1;
  margin-left: 15px;
  font-size: 16px;
}
</style>
