<template>
  <apollo-query
    ref="list"
    :query="queries.users"
    fetchPolicy="network-only"
    :variables="variables"
    class="apollo-query"
    @result="getUsers"
  >
    <template v-slot="{ result: { error, data }, isLoading }">
      <loader
        v-if="isLoading && !usersInChannel.length"
        class="apollo-query__loading"
        center
        :animated="false"
      ></loader>
      <div v-else-if="error" class="apollo-query__error">
        {{ $t("common.error") }}
      </div>
      <div
        v-show="(usersInChannel.length || foundUsersByName.length) && !error"
        class="apollo-query__result"
      >
        <ws-item-list
          v-for="user in users"
          :key="user.id"
          :class="{
            'ws-item-list--cursor-default': privateChatsDisabled,
          }"
          :item="user"
          :title="user.node.name"
          :image="user.node.avatar"
          :is-dot="true"
          :show-dot="user.node.online"
          :is-admin="user.id === data.chatFindChannel.admin.id"
        >
          <template slot="icon">
            <user-list-item
              v-if="user.id !== userId"
              v-show="!privateChatsDisabled"
              :user="user"
              :channel-id="currentChannelId"
              :icon="icon"
              :active-icon="activeIcon"
              @click="selectUser({ user, admin: data.chatFindChannel.admin })"
            ></user-list-item>
          </template>
        </ws-item-list>
      </div>
    </template>
  </apollo-query>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { auth, chats, room } from "@/store/modules/store.namespaces";
import { loadUsersAvatars } from "@/services/FileUploadService";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import throttle from "lodash/throttle";
import uniqBy from "lodash/uniqBy";
import Loader from "@/components/common/elements/Loader";
import { GET_USERS_FROM_CHANNELS } from "../GraphQl/query/query";

import WsItemList from "./ItemList";
import UserListItem from "./UserListItem";

/**
 * Cписок пользователей чата
 *
 * @vue-event {object} select-user - выбрать пользователя для чата
 */
export default {
  name: "ListUsers",
  components: {
    WsItemList,
    Loader,
    UserListItem,
  },
  props: {
    icon: {
      type: String,
      default: "fal fa-comment",
    },
    activeIcon: {
      type: String,
    },
    userName: {
      type: String,
    },
  },
  data() {
    return {
      queries: {
        users: GET_USERS_FROM_CHANNELS,
      },
      usersInChannel: [],
      foundUsersByName: [],
      totalCount: 0,
      lastUserId: 0,
      offset: 15,
    };
  },
  computed: {
    ...mapState(room, {
      commonChatId: state => +state.roomInfo.external_chat_id,
    }),
    ...mapState(auth, {
      groupChatId: state => +state.user.groupChatId,
    }),
    ...mapState(chats, ["channelId", "userId", "lastMsgId", "countMsgs"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    isCommonChat() {
      return this.channelId === this.commonChatId;
    },
    privateChatsDisabled() {
      return this.availableRoomModules.disablePrivateChat;
    },
    throttledHandleScroll() {
      return throttle(this.handleScroll, 500);
    },
    currentChannelId() {
      return this.channelId || this.commonChatId || this.groupChatId;
    },
    variables() {
      return {
        channel_id: !this.userName ? this.currentChannelId : this.commonChatId,
        first: this.offset,
        after: this.lastUserId,
        filter: { name: this.userName.trim() },
      };
    },
    users() {
      return this.userName ? this.foundUsersByName : this.usersInChannel;
    },
  },
  watch: {
    userName(val) {
      this.totalCount = 0;
      this.foundUsersByName = [];
      if (!val) {
        this.lastUserId = this.usersInChannel[this.usersInChannel.length - 1]?.id ?? 0;
      } else {
        this.lastUserId = 0;
      }
    },
  },
  mounted() {
    if (this.$refs.list) {
      this.$refs.list.$el.addEventListener("scroll", this.throttledHandleScroll);
    }
  },
  beforeDestroy() {
    if (this.$refs.list) {
      this.$refs.list.$el.removeEventListener("scroll", this.throttledHandleScroll);
    }
  },
  methods: {
    /**
     * Устанавливает параметр выбран ли пользователь и отправляет событие родителю
     *
     * @param {object} event - Событие
     * @param {object} event.user - Выбранный пользователь
     */
    selectUser({ user }) {
      const userInChat = user.node.channels_ids.includes(this.currentChannelId);
      if (userInChat) {
        user.select = !user.select;
        const foundUser = this.usersInChannel.find(u => u.id === user.id);
        if (foundUser) {
          foundUser.select = user.select;
        }
      } else {
        user.node.channels_ids.push(this.currentChannelId);
        this.usersInChannel.push(user);
      }
      this.$emit("select-user", { user, isUserInChat: userInChat });
    },
    async getUsers({ data }) {
      let users = data.chatFindChannel.userConnections.edges.map(user =>
        this.prepareUser(user, data.chatFindChannel.admin.id),
      );
      this.totalCount = data.chatFindChannel.userConnections.totalCount;
      const ids = users.filter(user => !user.node.avatar).map(user => user.id);
      if (ids.length) {
        users = await this.loadAvatars(ids, users);
      }

      this.setUsers(users);
    },
    /**
     * Загрузка аватаров пользователей
     *
     * @param {Array} ids - ид пользователей для загрузки аватаров
     * @param {Array} users - пользователи
     */
    async loadAvatars(ids, users) {
      const { data: avatars } = await loadUsersAvatars(ids);
      return users.map(user => {
        if (avatars[user.id]) {
          user.node.avatar = avatars[user.id];
        }
        return user;
      });
    },
    /**
     * Заполняем список пользователей канала или список найденых пользователей
     *
     * @param {Array} users - пользователя
     */
    setUsers(users) {
      if (!this.userName) {
        this.foundUsersByName = [];
        this.usersInChannel = uniqBy([...this.usersInChannel, ...users], "id");
        this.$emit("users", this.usersInChannel);
      } else {
        this.foundUsersByName = uniqBy([...this.foundUsersByName, ...users], "id");
      }
    },
    async handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.list.$el;
      // clientHeight + 10 - Бывают случаи что скрол не доходит до конца (допуск 10px)
      if (scrollHeight - scrollTop <= clientHeight + 10) {
        if (this.totalCount > this.usersInChannel.length && !this.userName) {
          this.lastUserId = this.usersInChannel[this.usersInChannel.length - 1]?.id || 0;
        }
        if (this.totalCount > this.foundUsersByName.length && this.userName) {
          this.lastUserId = this.foundUsersByName[this.foundUsersByName.length - 1]?.id || 0;
        }
      }
    },
    prepareUser(user, adminId) {
      let select = false;
      const { id } = user.node;
      if (this.userName) {
        const candidate = this.usersInChannel.find(u => u.id === id);
        select = candidate?.select ?? select;
        user.node.avatar = user.node.avatar ? user.node.avatar : candidate?.node.avatar ?? null;
        user.node.channels_ids = candidate?.node.channels_ids ?? user.node.channels_ids;
      }
      return { ...user, select, id, isAdmin: id === adminId };
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/_vars";

@gray: #dcdcdc;

.ws-users-list {
  margin-top: 15px;
  border-top: 1px solid @gray;
}

.ws-icon_gray {
  color: @gray;
}

.apollo-query {
  height: 100%;
  overflow: auto;

  &__error {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 18px;
    color: @error;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}

.apollo-query__result {
  display: flex;
  flex-direction: column;
}

.ws-item-list--cursor-default {
  cursor: default;
}
</style>
