<template>
  <room-style-provider
    #default="{ primaryButton, buttonIconStyles, iconStyle }"
  >
    <button
      class="ws-button"
      :class="buttonClasses"
      :style="styled ? primaryButton : ''"
      @click="handleClick"
    >
      <span
        v-if="icon"
        :class="['ws-icon', icon]"
        :style="styled ? buttonIconStyles : iconStyle"
      ></span>
      <span class="ws-button__text">
        <slot></slot>
      </span>
    </button>
  </room-style-provider>
</template>

<script>
import RoomStyleProvider from "@/components/common/roomStyle/RoomStyleProvider";

export default {
  name: "WsButton",
  components: {
    RoomStyleProvider,
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    icon: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    styled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonClasses() {
      return {
        [`ws-button--${this.type}`]: Boolean(this.type),
        "is-disabled": this.disabled,
      };
    },
  },
  methods: {
    handleClick(evt) {
      if (!this.disabled) this.$emit("click", evt);
    },
  },
};
</script>

<style scoped lang="less">
.ws-button {
  border-radius: 8px;
  border: 1px solid #f28b00;
  width: 100%;
  height: 39px;
  font-size: 16px;
  background: #fff;
  display: flex;
  align-items: center;
  color: #303030;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px #999;
  }
}

.ws-button .ws-icon {
  color: #f28b00;
}

.ws-button--primary {
  background: #f28b00;
  color: #fff;
}

.is-disabled {
  opacity: 0.5;
}

.ws-button .ws-icon:first-child {
  margin-right: 10px;
}
</style>
