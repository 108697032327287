<template>
  <room-style-provider #default="{ avatarStyle }">
    <div
      class="ws-avatar"
      :class="{ 'ws-avatar--select': isSelectAvatar }"
      :style="[sizeStyle, avatarStyle]"
      @click="handleClick"
    >
      <img v-if="src && !error" :src="src" :alt="alt" :style="avatarStyle" @error="error = true" />
      <span v-else-if="!faIcon" :style="avatarStyle">{{ setLetterAvatar(title) }}</span>
      <i v-else class="fa ws-avatar--fa" :class="[faIcon]" :style="avatarStyle" />
    </div>
  </room-style-provider>
</template>

<script>
import RoomStyleProvider from "@/components/common/roomStyle/RoomStyleProvider";

const titleLetters = /[^a-zA-Z ][^а-яА-Я ][^0-9]/g;

export default {
  name: "WsAvatar",
  components: {
    RoomStyleProvider,
  },
  props: {
    size: {
      type: Number,
    },
    src: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    faIcon: {
      type: String,
    },
    alt: {
      type: String,
      default: "image",
    },
  },
  data() {
    return {
      sizeStyle: {
        width: `${this.$props.size}px`,
        height: `${this.$props.size}px`,
        flex: `0 0 ${this.$props.size}px`,
      },
      error: false,
    };
  },
  computed: {
    isSelectAvatar() {
      return !this.src && this.faIcon;
    },
  },
  watch: {
    src() {
      this.error = false;
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
    setLetterAvatar(title) {
      const formattedTitle = title
        .trim()
        .replace(titleLetters, " ")
        .split(" ");
      if (formattedTitle.length < 2) {
        return formattedTitle[0].charAt(0).toUpperCase();
      }
      return (
        formattedTitle[0].charAt(0).toUpperCase() +
        formattedTitle[formattedTitle.length - 1].charAt(0).toUpperCase()
      );
    },
  },
};
</script>

<style scoped lang="less">
@base: #f28b02;

@white: #fff;

@bg-avatar: rgba(3, 3, 3, 0.09);

@avatar: #808080;

.ws-avatar {
  display: flex;
  flex: 0 0 40px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: @white;
  cursor: inherit;
  background: @base;
  border-radius: 50%;

  &--fa {
    font-size: 30px;
    color: @avatar;
  }
}

.ws-avatar--select {
  background-color: @bg-avatar;
}

.ws-avatar img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
