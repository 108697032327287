<script>
import { mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";

export default {
  name: "RoomStyleProvider",
  computed: {
    ...mapState(room, ["styleColors"]),
    styles() {
      return {
        primaryButton: this.primaryButtonStyles,
        inputStyles: this.inputStyles,
        iconStyle: this.iconStyle,
        avatarStyle: this.avatarStyle,
        buttonIconStyles: this.buttonIconStyles,
      };
    },
    primaryButtonStyles() {
      return {
        backgroundColor: this.styleColors.baseColor,
        color: this.styleColors.buttonActiveTextColor,
        borderColor: this.styleColors.baseBorderColor,
      };
    },
    inputStyles() {
      return {
        borderColor: this.styleColors.baseColor,
      };
    },
    buttonIconStyles() {
      return {
        color: this.styleColors.buttonActiveTextColor,
      };
    },
    iconStyle() {
      return {
        color: this.styleColors.baseColor,
      };
    },
    avatarStyle() {
      return {
        backgroundColor: this.styleColors.baseColor,
        color: this.styleColors.buttonActiveTextColor,
      };
    },
  },
  render() {
    return this.$scopedSlots.default({
      ...this.styles,
    });
  },
};
</script>
