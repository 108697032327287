<template>
  <div class="header-chat">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "HeaderChat",
};
</script>

<style scoped lang="less">
.header-chat {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  width: 100%;
  min-height: 51px;
  background: #fff;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.16276);
}
</style>
