<template>
  <div class="setting-group-chat">
    <header-chat>
      <div class="header-chat__button" @click="goBack">
        <ws-icon light xl color="primary">chevron-left</ws-icon>
        <div class="header-chat__button-text">{{ $t("common.back") }}</div>
      </div>

      <div class="info-chat">
        <ws-avatar :src="avatarChannel || ''" :title="newTitleChannel"></ws-avatar>
        <div class="info-chat__title">{{ titleChannel }}</div>
      </div>
    </header-chat>
    <div class="settings-body">
      <div v-if="!isPublicChannel && isAdmin" class="editing-container">
        <div class="container-avatar">
          <ws-avatar
            :src="avatarChannel"
            fa-icon="fa-camera"
            :size="72"
            :title="newTitleChannel"
            @click="toggleImagePopup"
          ></ws-avatar>
        </div>
        <div class="container-change-title-group">
          <ws-input :value="newTitleChannel" :invalid="!!errorMessage" @input="changeChannelTitle">
            <div slot="append" class="container-button">
              <ws-button type="primary" :disabled="!isTitleChange" @click="updateNameChat">{{
                $t("Chat.updateNameChatBtn")
              }}</ws-button>
            </div>
          </ws-input>
          <div v-if="errorMessage" class="participants-info participants-info--invalid">
            {{ errorMessage }}
          </div>
          <div v-else class="participants-info">
            {{
              $t("Chat.participantsInfo", {
                participantsCount: usersTotalCount,
                usersOnlineCount: onlineCount,
              })
            }}
          </div>
        </div>
      </div>
      <div class="item-setting">
        <div class="container-title-setting">
          <div class="title-item-setting">{{ $t("Chat.participants") }}</div>
        </div>
        <ws-input
          v-if="!isPrivateChannel"
          :placeholder="$t('common.search')"
          prefix-icon="icon-magnifier"
          @input="searchUser($event)"
        />
      </div>
      <ws-list-users
        :user-name="userName"
        :isDot="true"
        :active-icon="activeIcon"
        :icon="icon"
        :is-admin="isAdmin"
        @select-user="selectUser"
        @users="updateUsersList"
      />

      <avatar-preview
        v-show="isShowAvatarPreview"
        :src="avatarChannel || ''"
        @close="closePreview"
      />
      <input
        v-show="false"
        ref="upload"
        type="file"
        accept="image/jpeg, image/png"
        @change="previewUploadedImage"
      />
      <select-popup
        v-show="isShowImagePopup"
        :show-preview="Boolean(avatarChannel)"
        :show-remove="Boolean(avatarChannel)"
        class="setting-group-chat__popup-image-select"
        @view-image="toggleImagePreview"
        @load-image="loadImage"
        @remove-image="removeImage"
      ></select-popup>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { GET_CHANNEL_INFO } from "@/components/chat/GraphQl/query/query";
import {
  UPDATE_NAME_CHAT,
  REMOVE_USER_FROM_CHANNEL,
  ADD_USER_TO_CHANNEL,
} from "@/components/chat/GraphQl/mutation/mutation";
import { uploadChatImage } from "@/services/FileUploadService";
import { chats, room } from "@/store/modules/store.namespaces";
import { TOO_LONG_ERROR_TYPE } from "@/constants/chats/chats-consts";
import { CHATS_SET_PROPS } from "@/store/modules/chats/mutation-types";

import WsIcon from "@/components/base/WsIcon";
import HeaderChat from "./Header";
import AvatarPreview from "./AvatarPreview";
import SelectPopup from "./SelectPopup";
import WsAvatar from "./Avatar";
import WsInput from "./Input";
import WsListUsers from "./List";
import WsButton from "./Button";

export default {
  name: "SettingGroupChat",
  components: {
    SelectPopup,
    AvatarPreview,
    HeaderChat,
    WsButton,
    WsInput,
    WsAvatar,
    WsListUsers,
    WsIcon,
  },
  props: {
    avatarChannel: {
      type: String,
      default: "",
    },
    titleChannel: {
      type: String,
      default: "",
    },
    typeChannel: {
      type: String,
      default: "public",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    usersTotalCount: {
      type: Number,
      default: 0,
    },
    onlineCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      users: [],
      usersRemove: new Set(),
      usersAdd: new Set(),
      userName: "",
      isShowAvatarPreview: false,
      isShowImagePopup: false,
      newTitleChannel: this.titleChannel,
      queries: {
        channelInfo: GET_CHANNEL_INFO,
      },
      errorMessage: "",
    };
  },
  computed: {
    ...mapState(chats, ["channelId", "userId", "lastMsgId", "countMsgs"]),
    ...mapState(room, {
      entityId: state => state.roomInfo?.external_chat_entity_id,
    }),
    isPublicChannel() {
      return this.typeChannel === "public";
    },
    isPrivateChannel() {
      return this.typeChannel === "private";
    },
    icon() {
      return this.isPublicChannel || !this.isAdmin ? "fas fa-comment" : "fal fa-times-circle";
    },
    activeIcon() {
      return this.isPublicChannel || !this.isAdmin
        ? "fas fa-comment"
        : this.isPrivateChannel
        ? "fal fa-times-circle"
        : "fal fa-plus-circle";
    },
    isTitleChange() {
      return this.newTitleChannel !== this.titleChannel;
    },
  },
  watch: {
    userName(name) {
      if (!name && this.usersAdd.size) this.addUsersToChat();
    },
  },
  mounted() {
    this.newTitleChannel = this.titleChannel;
  },
  methods: {
    ...mapMutations(chats, {
      updateChatsProps: CHATS_SET_PROPS,
    }),
    closePreview() {
      this.isShowAvatarPreview = false;
    },
    toggleImagePopup() {
      this.isShowImagePopup = !this.isShowImagePopup;
    },
    toggleImagePreview() {
      this.isShowAvatarPreview = !this.isShowAvatarPreview;
      this.isShowImagePopup = false;
    },
    loadImage() {
      this.$refs.upload.click();
    },
    updateChannelInfoQuery() {
      return this.$apollo.query({
        query: this.queries.channelInfo,
        fetchPolicy: "network-only",
        debounce: 1,
        variables: {
          channel_id: this.channelId,
        },
      });
    },
    async previewUploadedImage() {
      this.isShowImagePopup = false;
      const file = this.$refs.upload.files[0];
      this.$emit("avatar-loading");
      let avatarUpdateIntervalId;
      try {
        await uploadChatImage({
          file,
          userId: this.userId,
          channelId: this.channelId,
        });
        const vm = this;
        avatarUpdateIntervalId = setInterval(() => {
          vm.updateChannelInfoQuery().then(data => {
            const { avatar } = data.data.chatFindChannel;
            if (avatar !== vm.avatarChannel) {
              vm.$emit("avatar-updated");
              clearInterval(avatarUpdateIntervalId);
            }
          });
        }, 1000);
      } catch (e) {
        this.$emit("avatar-updated");
        if (avatarUpdateIntervalId) clearInterval(avatarUpdateIntervalId);
      }
    },
    removeImage() {
      this.$apollo
        .mutate({
          mutation: UPDATE_NAME_CHAT,
          variables: {
            input: { avatar: null },
            channel_id: +this.channelId,
          },
        })
        .then(() => {
          this.$emit("avatar-updated");
          this.isShowImagePopup = false;
        });
    },
    searchUser(value) {
      this.userName = value;
    },
    selectUser({ user, isUserInChat }) {
      if ((this.isPublicChannel && this.userId !== user.id) || !this.isAdmin) {
        this.$emit("start-private", user);
      } else if (!this.isPrivateChannel && !user.isAdmin && isUserInChat && this.isAdmin) {
        this.usersRemove.has(user.id)
          ? this.usersRemove.delete(user.id)
          : this.usersRemove.add(user.id);
      } else if (!this.isPrivateChannel && !isUserInChat && this.isAdmin) {
        this.usersAdd.has(user.id) ? this.usersAdd.delete(user.id) : this.usersAdd.add(user.id);
      }
    },
    goBack(evt) {
      this.$emit("click", evt);
      if (this.usersRemove.size) this.removeUsersFromChat();
      if (this.usersAdd.size) this.addUsersToChat();
    },
    changeChannelTitle(name) {
      this.newTitleChannel = name;
    },
    removeUsersFromChat() {
      this.$apollo
        .mutate({
          mutation: REMOVE_USER_FROM_CHANNEL,
          variables: {
            user_ids: Array.from(this.usersRemove),
            channel_id: +this.channelId,
          },
        })
        .then(() => {
          this.users.forEach(user => {
            this.$set(user, "select", false);
          });
          this.usersRemove.clear();
        });
    },
    addUsersToChat() {
      const users = Array.from(this.usersAdd);
      users.forEach(userId => {
        this.$apollo
          .mutate({
            mutation: ADD_USER_TO_CHANNEL,
            variables: {
              user_id: userId,
              channel_id: +this.channelId,
            },
          })
          .catch(error => {
            throw new Error(error);
          });
      });
    },
    async updateNameChat() {
      try {
        this.errorMessage = "";
        await this.$apollo.mutate({
          mutation: UPDATE_NAME_CHAT,
          variables: {
            input: { name: this.newTitleChannel },
            channel_id: +this.channelId,
          },
        });
        if (this.usersRemove.size) this.removeUsersFromChat();
        if (this.usersAdd.size) this.addUsersToChat();
        this.$emit("title-updated", this.newTitleChannel);
      } catch (e) {
        if (e.message?.includes(TOO_LONG_ERROR_TYPE)) {
          this.errorMessage = this.$t("Chat.nameTooLong");
        }
      }
    },
    updateUsersList(users) {
      this.users = users;
    },
  },
};
</script>

<style lang="less">
@base: #f28b00;

@black: #303030;

.messages {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.setting-group-chat__back-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 90px;
  font-size: 12px;
  color: @base;
  cursor: pointer;
}

.ws-icon {
  font-size: 21px;
}

.setting-group-chat {
  position: absolute;
  top: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 455px;
  background: #fff;

  .ws-input__inner {
    color: @black;
  }
}

.container-button {
  width: 46px;
  height: 36px;
  cursor: pointer;

  .ws-button__text {
    font-weight: 600;
  }
}

.editing-container {
  display: flex;
  align-items: center;
  margin: 20px 10px 0;

  .ws-button {
    min-width: auto;
    height: 100%;
    border: 0;
    border-radius: 0 3px 3px 0;

    .ws-button__text {
      margin: auto;
    }
  }

  .chat-input {
    height: 31px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ws-input {
    flex: 1;
  }
}

.container-change-title-group {
  flex: 1;

  .ws-input__inner {
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.item-setting {
  margin: 0 10px;
  margin-top: 15px;
}

.container-avatar {
  margin-right: 15px;
}

.participants-info {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  color: rgba(#000000, 0.4);

  &--invalid {
    color: #f05735;
  }
}

.setting-chat {
  padding: 0 10px 15px;
}

.container-title-setting {
  display: flex;
}

.count-participants {
  color: #777777;
}

.title-item-setting {
  flex: 1;
  margin-bottom: 10px;
  color: #000;
}

.header-chat__item {
  margin-right: 8px;
  overflow-x: hidden;
  white-space: nowrap;
}

.container-icon {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 21px;
  color: @base;
}

.header-chat__button {
  display: flex;
  align-items: center;
  width: 100px;
  padding-left: 5px;
  cursor: pointer;

  &-text {
    margin-left: 5px;
  }
}

.info-chat {
  display: flex;
  align-items: center;
  max-width: 230px;
  overflow-x: hidden;

  &__title {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.settings-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
</style>
