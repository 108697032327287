<template>
  <room-style-provider #default="{ primaryButton, iconStyle }">
    <div class="message" :class="{ own: isMessageOwner }">
      <div class="user-name">{{ userName }}</div>
      <div class="message-content" :style="isMessageOwner && primaryButton">
        <p class="message-text">{{ message.node.text }}</p>
        <div class="message-time" :style="isMessageOwner && primaryButton">
          {{ formattedDate(message.node.created) }}
        </div>
      </div>
    </div>
  </room-style-provider>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { chats } from "@/store/modules/store.namespaces";
import RoomStyleProvider from "@/components/common/roomStyle/RoomStyleProvider";

moment.locale("ru");
export default {
  name: "Message",
  components: {
    RoomStyleProvider,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(chats, ["userId"]),
    isMessageOwner() {
      const { user } = this.message.node;
      return !!user && !user.is_guest && this.userId === user?.id;
    },
    userName() {
      const { user } = this.message.node;
      return this.isMessageOwner
        ? user.is_guest
          ? this.$t("Chat.guest")
          : this.$t("Chat.ownUserName")
        : this.normalizedUserName(user?.name || this.$t("Chat.anonymous"));
    },
  },
  methods: {
    formattedDate(date) {
      return moment(date).format("LT");
    },
    normalizedUserName(name) {
      if (name === "Guest") return this.$t("Chat.guest");
      return name;
    },
  },
};
</script>

<style scoped lang="less">
.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px 10px 10px;

  &:first-child {
    margin-top: 10px;
  }
}

.message-time {
  flex: 1;
  font-size: 14px;
  color: #353535;
  text-align: right;
  opacity: 0.5;
}

.message-content {
  display: flex;
  align-items: baseline;
  max-width: 315px;
  padding: 15px;
  color: #353535;
  background: #f4f4f4;
  border-radius: 8px;
}

.message-text {
  margin: 0 10px 0 0;
  word-break: break-word;
}

.user-name {
  padding: 0 15px;
  font-size: 14px;
  color: #727272;
}

.message.own {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.own .message-content {
  color: #000000;
  background: #fdedd8;
}
</style>
