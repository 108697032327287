<template>
  <room-style-provider #default="{ primaryButton }">
    <div class="user-list-channel">
      <header-chat>
        <div
          class="setting-group-chat__back-button header-chat__item"
          @click="handleClick"
        >
          <ws-icon :class="'icon-arrow-left'"></ws-icon>
          {{ $t("common.back") }}
        </div>

        <div class="info-chat header-chat__item">
          <ws-avatar
            :src="avatarChannel"
            :title="titleChannel"
            class="header-chat__item"
          ></ws-avatar>
          <div class="header-chat__item">{{ titleChannel }}</div>
        </div>
        <div class="container-icon">
          <ws-icon class="icon-settings"></ws-icon>
        </div>
      </header-chat>
      <div class="editing-container">
        <div class="container-avatar">
          <ws-avatar
            :src="avatarChannel"
            :size="72"
            :title="titleChannel"
          ></ws-avatar>
        </div>
        <div class="container-change-title-group">
          <div class="user-list-channel__title">{{ titleChannel }}</div>
        </div>
      </div>
    </div>
  </room-style-provider>
</template>

<script>
import { GET_USERS_FROM_CHANNELS } from "../GraphQl/query/query";
import HeaderChat from "./Header";
import WsIcon from "./Icon";
import WsAvatar from "./Avatar";
import RoomStyleProvider from "@/components/common/roomStyle/RoomStyleProvider";

export default {
  name: "ListUsersGroupChat",
  components: {
    HeaderChat,
    WsIcon,
    WsAvatar,
    RoomStyleProvider,
  },
  data() {
    return {
      avatarChannel: "",
      titleChannel: "",
    };
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>

<style scoped lang="less">
@gray: #b7b7b7;

@black: #303030;

.user-list-channel {
  position: absolute;
  top: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
}

.user-list-channel__title {
  font-size: 16px;
  color: @black;
}

.setting-chat {
  padding: 0 10px 15px;
}

.container-title-setting {
  display: flex;
}

.count-participants {
  color: #777777;
}

.title-item-setting {
  flex: 1;
  margin-bottom: 10px;
  color: #000;
}

.item-setting {
  margin: 0 10px;
  margin-top: 15px;
}

.editing-container {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 20px 10px 0;

  .ws-button {
    height: 31px;
    border-radius: 3px;
  }

  .chat-input {
    height: 31px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ws-input {
    flex: 1;
  }
}

.container-change-title-group {
  flex: 1;
}

.container-avatar {
  margin-right: 15px;
}

.participants-info {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  color: #000000;
  opacity: 0.4;
}

.divider {
  width: 1px;
  height: 12px;
  margin: 0 5px;
  background: #000000;
}

.container-ws-icon {
  .ws-icon {
    color: @gray;
  }
}

.setting-group-chat__back-button {
  cursor: pointer;
}

.header-chat__item {
  white-space: nowrap;
}
</style>
