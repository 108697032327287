<template>
  <room-style-provider #default="{ inputStyles }">
    <div class="user-input user-input--form">
      <anonymous-checkbox
        v-if="hasAnonymous && !isGuest"
        id="messageAnonymous"
        v-model="isAnonymousMessage"
        class="user-input__anonymous-checkbox"
      ></anonymous-checkbox>
      <form
        ref="usernameInput"
        class="ws-form"
        @submit="handleClick"
        @keydown.enter="handleKeyDown"
      >
        <ws-input
          v-model="message"
          type="textarea"
          :placeholder="$t('Chat.startWriteMessage')"
          :style="inputStyles"
        >
          <ws-icon
            slot="append"
            class="user-input__send"
            light
            xxl
            color="primary"
            @click.native="handleClick"
          >
            paper-plane
          </ws-icon>
        </ws-input>
      </form>
    </div>
  </room-style-provider>
</template>
<script>
import AnonymousCheckbox from "@/components/common/messenger/AnonymousCheckbox";
import { SET_MESSENGER_ANONYMOUS } from "@/store/modules/messenger/mutation-types";
import * as roomGetterKeys from "@/store/modules/common/getter-types";
import { messenger, room, auth } from "@/store/modules/store.namespaces";
import { mapMutations, mapState, mapGetters } from "vuex";
import RoomStyleProvider from "@/components/common/roomStyle/RoomStyleProvider";
import WsIcon from "@/components/base/WsIcon";
import WsInput from "./Input";

export default {
  name: "UserInput",
  components: {
    WsInput,
    AnonymousCheckbox,
    RoomStyleProvider,
    WsIcon,
  },
  computed: {
    ...mapState(messenger, ["anonymous"]),
    ...mapState(auth, {
      isGuest: state => !!state.user.is_guest,
    }),
    ...mapGetters(room, {
      availableRoomModules: roomGetterKeys.AVAILABLE_ROOM_MODULES,
    }),
    isAnonymousMessage: {
      get() {
        return this.anonymous;
      },
      set(value) {
        this.setMessengerAnonymous(value);
      },
    },
    hasAnonymous() {
      return this.availableRoomModules.allowAnonymity;
    },
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    ...mapMutations(messenger, {
      setMessengerAnonymous: SET_MESSENGER_ANONYMOUS,
    }),
    handleKeyDown(event) {
      event.preventDefault();
      if (event.ctrlKey) {
        this.addNewLine();
        return;
      }
      if (!this.message.trim().length) return;
      this.sendMessage();
    },
    sendMessage() {
      this.$emit("sendMessage", this.message);
      this.$refs.usernameInput.blur();
      this.message = "";
    },
    addNewLine() {
      this.message += "\n";
    },
    handleClick(e) {
      if (!this.message.trim().length) return;
      this.sendMessage();
    },
  },
};
</script>

<style lang="less">
@gray: #dcdcdc;
.user-input.user-input--form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.user-input {
  position: relative;
  bottom: 0;
  flex: 0 0 auto;
  width: 100%;
  padding: 6px 8px 6px 8px;
  background: #fff;
  border-top: 1px solid @gray;

  .ws-textarea__inner {
    padding-right: 57px;
    color: #000;
    border: none;
  }

  .ws-textarea {
    border-radius: 8px;
  }

  .ws-button {
    font-size: 24px;
  }

  &__anonymous-checkbox {
    position: relative;
    right: 0;
    display: flex;
    justify-content: flex-end;
    margin: 5px;
  }
}

.ws-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 39px;
  padding: 10px;
  font-size: 20px;
  color: #303030;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
}

.user-input__send {
  display: flex;
  align-items: center;
  width: 100%;
  height: 39px;
  padding: 10px;
}

.ws-form {
  width: 100%;
  height: 59px;
  background-color: #ffffff;
  border: solid 1px #ebebeb;
  border-radius: 8px;
}
</style>
