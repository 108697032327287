<template>
  <div class="message-list" :class="{ 'message-list_direction-bottom': directionMessagesBottom }">
    <div
      ref="messageListInner"
      class="message-list__inner-container"
      :class="{ 'message-list__inner-container--screen': isScreen }"
    >
      <message v-for="message in messages" :key="message.id" :message="message" />
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapState } from "vuex";
import { chats } from "@/store/modules/store.namespaces";
import Message from "./Message";
import { CHAT_SET_MESSAGE_READED } from "../GraphQl/mutation/mutation";

export default {
  name: "MessageList",
  components: {
    Message,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    isScreen: {
      type: Boolean,
      default: false,
    },
    isScrollBottom: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      directionMessagesBottom: true,
      oldScroll: 0,
      handleDebouncedScroll: null,
      chatHeight: 0,
    };
  },
  computed: {
    ...mapState(chats, ["channelId", "userId", "lastMsgId", "countMsgs"]),
    ...mapState(chats, {
      countMsgs: state => (state.countMsgs === 10 ? 1 : +state.countMsgs),
    }),
    refMessages() {
      return this.$refs.messageListInner;
    },
  },
  watch: {
    chatHeight(newHeight, oldHeight) {
      this.refMessages.scrollTop = newHeight - oldHeight;
    },
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 400);
    this.refMessages.addEventListener("scroll", this.handleDebouncedScroll);
    const nextMessageId = this.lastMsgId + this.countMsgs;
    if (this.isScrollBottom) this.scrollToBottom();
    this.$apollo.mutate({
      mutation: CHAT_SET_MESSAGE_READED,
      variables: {
        last_id: +nextMessageId,
        channel_id: +this.channelId,
      },
    });
    this.chatHeight = this.refMessages.scrollHeight;
  },
  updated() {
    if (this.isScrollBottom) {
      this.$nextTick(this.scrollToBottom);
    } else {
      this.calcChatHeight();
    }
  },
  beforeDestroy() {
    this.refMessages.removeEventListener("scroll", this.handleDebouncedScroll);
  },
  methods: {
    calcChatHeight() {
      this.chatHeight = this.refMessages.scrollHeight;
    },
    handleScroll() {
      const { scrollTop } = this.refMessages;
      if (scrollTop <= this.oldScroll && scrollTop <= 100) {
        this.$emit("load-more-messages");
      }
    },
    scrollToBottom() {
      const { scrollHeight, clientHeight } = this.refMessages;
      this.refMessages.scrollTop = scrollHeight - clientHeight;
    },
  },
};
</script>

<style scoped lang="less">
.message-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 12px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &__inner-container {
    height: 100%;
    overflow-y: auto;

    &--screen {
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.message-list_direction-bottom {
  justify-content: flex-end;
}
</style>
