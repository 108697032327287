<template>
  <div class="select-popup">
    <ws-button v-if="showPreview" lg color="primary" text @click="$emit('view-image')">
      <ws-icon light xl>camera</ws-icon>
      {{ $t("Chat.viewImage") }}
    </ws-button>
    <ws-button v-if="showLoad" lg color="primary" text @click="$emit('load-image')">
      <ws-icon light xl>camera</ws-icon>
      {{ $t("Chat.loadImage") }}
    </ws-button>
    <ws-button v-if="showRemove" lg color="primary" text @click="$emit('remove-image')">
      <ws-icon light xl>camera</ws-icon>
      {{ $t("Chat.removeImage") }}
    </ws-button>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "SelectPopup",
  components: {
    WsButton,
    WsIcon,
  },
  props: {
    showPreview: {
      type: Boolean,
      default: true,
    },
    showLoad: {
      type: Boolean,
      default: true,
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="less" scoped>
.select-popup {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #808080;
  border-radius: 5px 5px 0 0;
}
</style>
