import { nanoid } from "nanoid";
import AdminApi2HttpService from "@/services/http/AdminApi2HttpService";
import StorageHttpService from "@/services/http/StorageHttpService";

/**
 * @param params
 * @param params.file
 * @param params.userId
 * @param params.channelId
 */
export function uploadChatImage({ file, userId, channelId }) {
  const formData = new FormData();
  const uuid = nanoid(32);
  const headers = { "x-id": userId };
  formData.append("strategy", "image");
  formData.append("data", file, file.name);
  formData.append("entity_id", `changeChannelAvatar:${uuid}:${channelId}`);
  return StorageHttpService.post("", formData, { headers });
}

/**
 * Загрузка аватаров пользователей
 *
 * @param {Array} ids - ид пользователей
 */
export function loadUsersAvatars(ids) {
  return AdminApi2HttpService.post("user/avatars", ids);
}
