<template>
  <div class="container-ws-icon" @click="$emit('click')">
    <ws-icon :class="userIcon" :style="iconStyles"></ws-icon>
  </div>
</template>

<script>
import WsIcon from "./Icon";

export default {
  name: "UserListItem",
  components: {
    WsIcon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    channelId: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      default: "fal fa-comment",
    },
    activeIcon: {
      type: String,
    },
  },
  computed: {
    isUserInChannel() {
      return this.user.node.channels_ids?.includes(this.channelId);
    },
    userIcon() {
      return this.isUserInChannel
        ? this.user.select
          ? this.activeIcon
          : this.icon
        : this.activeIcon;
    },
    iconStyles() {
      const colors = [
        {
          color: "#F20000",
          check: () => this.userIcon.includes("times-circle"),
        },
        { color: "#B7B7B7", check: () => this.userIcon.includes("comment") },
        { color: "#F28B00", check: () => this.userIcon.includes("plus") },
      ];
      const foundColor = colors.find(color => color.check());
      return foundColor
        ? {
            color: foundColor.color,
          }
        : {};
    },
  },
};
</script>
<style lang="less">
.container-ws-icon {
  cursor: pointer;
}
</style>
