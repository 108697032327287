import gql from "graphql-tag";

export const GET_PROFILE = gql`
  {
    profile {
      user {
        id
        name
      }
    }
  }
`;

export const GET_USERS_FROM_CHANNELS = gql`
  query chatFindChannel(
    $channel_id: Int!
    $first: Int!
    $after: Int!
    $filter: UserFilterInput
    $except_ids: [Int]
  ) {
    chatFindChannel(channel_id: $channel_id) {
      id
      name
      type
      avatar
      userConnections(first: $first, after: $after, filter: $filter, except_ids: $except_ids) {
        totalCount
        edges {
          node
          id
        }
      }
      admin {
        id
      }
    }
  }
`;

export const GET_CHANNEL_INFO = gql`
  query chatFindChannel($channel_id: Int!) {
    chatFindChannel(channel_id: $channel_id) {
      id
      name
      type
      avatar
    }
  }
`;

export const GET_MESSAGES_FROM_CHANNEL = gql`
  query chatFindChannel($channel_id: Int!, $first: Int!, $after: Int!, $descending: Boolean) {
    chatFindChannel(channel_id: $channel_id) {
      id
      name
      type
      avatar
      messages(first: $first, after: $after, descending: $descending) {
        totalCount
        edges {
          node
          id
        }
        additionalInfo
      }
      userConnections(first: 0, after: 0) {
        totalCount
        additionalInfo
      }
      admin {
        id
        name
      }
    }
  }
`;

export const MY_CHANNELS = gql`
  query myChannels($first: Int!, $after: Int!, $filter: ChannelFilterInput) {
    myChannels(first: $first, after: $after, filter: $filter) {
      id
      name
      avatar
      type
      entityId
      userConnections(first: 2, after: 2) {
        edges {
          id
        }
      }
      messages {
        additionalInfo
      }
    }
  }
`;
