<template>
  <room-style-provider #default="{ iconStyle }">
    <i
      class="ws-icon"
      :style="iconStyle"
      @click="$emit('click')"
    />
  </room-style-provider>
</template>

<script>
import RoomStyleProvider from "@/components/common/roomStyle/RoomStyleProvider";

export default {
  name: "ChatIcon",
  components: {
    RoomStyleProvider,
  },
};
</script>
