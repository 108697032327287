<template>
  <div class="ws-badge">
    <slot></slot>
    <sup
      v-if="showDot"
      v-show="hidden"
      class="ws-badge__content"
      :class="{'is-dot': isDot}"
    >{{ value }}</sup>
  </div>
</template>

<script>
export default {
  name: "WsBadge",
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    max: Number,
    isDot: Boolean,
    showDot: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="less">
.ws-badge {
  position: relative;
  display: flex;
}

.ws-badge__content {
  position: absolute;
  top: 4px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  padding: 0 3px;
  font-size: 9px;
  color: #fff;
  background: #373737;
  border-radius: 50%;
  transform: translateY(-50%) translateX(100%);
}

@dot-color: #13c809;

.is-dot {
  right: 5px;
  width: 6px;
  min-width: 0;
  height: 6px;
  padding: 0;
  background: @dot-color;
  border: 1px solid #fff;
}
</style>
