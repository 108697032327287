export const channelTypes = {
  0: "private",
  1: "group",
  2: "public",
  private: 0,
  group: 1,
  public: 2,
};

export const TOO_LONG_ERROR_TYPE = "ER_DATA_TOO_LONG";
