import gql from "graphql-tag";

export const REMOVE_CHANNEL = gql`
  mutation chatChannel($channel_id: Int!) {
    chatChannel(channel_id: $channel_id) {
      deleteChannel
    }
  }
`;

export const REMOVE_USER_FROM_CHANNEL = gql`
  mutation chatChannel($channel_id: Int!, $user_ids: [Int]) {
    chatChannel(channel_id: $channel_id) {
      removeUserFromChannel(user_ids: $user_ids)
    }
  }
`;

export const START_PRIVATE_CHANNEL = gql`
  mutation startPrivate($user_id: Int!, $entity_id: Int!) {
    startPrivate(user_id: $user_id, entity_id: $entity_id) {
      id
      name
    }
  }
`;

export const UPDATE_NAME_CHAT = gql`
  mutation chatChannel($input: ChannelInput, $channel_id: Int!) {
    chatChannel(channel_id: $channel_id) {
      updateChannel(input: $input) {
        id
        name
        type
        avatar
      }
    }
  }
`;

export const CHAT_SET_MESSAGE_READED = gql`
  mutation chatChannel($last_id: Int!, $channel_id: Int!) {
    chatChannel(channel_id: $channel_id) {
      setUserLastId(last_id: $last_id)
    }
  }
`;

export const CHAT_CREATE_CHANNEL = gql`
  mutation chatCreateChannel(
    $name: String
    $type: Int!
    $access_uri: String
    $user_ids: [Int]
    $entity_id: Int!
  ) {
    chatCreateChannel(
      name: $name
      type: $type
      access_uri: $access_uri
      user_ids: $user_ids
      entity_id: $entity_id
    ) {
      id
      name
      type
    }
  }
`;

export const ADD_USER_TO_CHANNEL = gql`
  mutation addUserToChannel($user_id: Int, $channel_id: Int!) {
    addUserToChannel(user_id: $user_id, channel_id: $channel_id) {
      id
      name
      type
    }
  }
`;

export const CREATE_CHANNEL = gql`
  mutation chatCreateChannel($name: String, $type: Int!) {
    chatCreateChannel(name: $name, type: $type) {
      id
      name
    }
  }
`;

export const CHAT_CREATE_NEW_USER = gql`
  mutation chatCreateNewUser($name: String!, $is_guest: Boolean!) {
    chatCreateNewUser(name: $name, is_guest: $is_guest) {
      id
      name
    }
  }
`;

export const ADD_MESSAGE_TO_CHANNEL = gql`
  mutation addMessageChannel(
    $channel_id: Int!
    $text: String!
    $anonymous: Boolean
  ) {
    chatChannel(channel_id: $channel_id) {
      addMessage(text: $text, anonymous: $anonymous) {
        id
        local_id
        user {
          name
          id
          is_guest
        }
        text
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation addMessageChannel($channel_id: Int!, $user_id: Int!) {
    chatChannel(channel_id: $channel_id) {
      addMessage(text: $text) {
        id
        local_id
        user {
          name
          id
        }
        text
      }
    }
  }
`;
